.switch-container {
    display: flex;
    position: relative;
  }
  
  .switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .switch-label {
    display: block;
    width: 42px;
    height: 22px;
    background: #ccc;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .switch-label:active .switch-button {
    width: 28px;
  }
  
  .switch-checkbox:checked + .switch-label {
    background: #00d1b2;
  }
  
  .switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }
  
  .switch-checkbox:checked + .switch-label .switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .switch-label:active .switch-button {
    width: 28px;
  }
  