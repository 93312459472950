.loader-wrapper {
	position: fixed; /* Use fixed to overlay the loader on the entire page */
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: 1;
	z-index: 9999; /* High z-index to ensure it's on top of other content */
	transition: opacity .3s;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.7); /* Optional: add a background to make the loader stand out */
}

.loader {
	height: 80px;
	width: 80px;
	border: 8px solid rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	border-top: 8px solid #3498db;
	animation: spin 1.2s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
