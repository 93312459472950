body {
  --primary: #631d76;
  --secondary: #9e4770;
  --white: #fbfbfb;
  --dark: #2e2532;
  --black: #201a23;
}

.product-card figure {
  text-align: center;
  padding: 5px;
}

.navbar ul.social {
  display: flex;
}
.navbar ul.social li {
  margin-right: 20px;
  text-decoration: none;
}
.navbar ul.social li a {
  color: var(--white);
}

.banner {
  margin: 50px;
}

.columns.products {
  flex-wrap: wrap;
}

.card-footer-item i {
  margin-right: 10px;
}

.navbar-bottom.navbar {
  align-items: center;
}

.navbar-top.navbar {
  background-color: var(--primary);
}

.navbar-buttons {
  display: flex;
}

.navbar-top .container.navbar-container,
.navbar-bottom .container.navbar-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 5%;
  align-items: center;
}

#cart h3 {
  margin-top: 50px;
}

#cart .cart-item {
  margin-bottom: 10px;
  padding: 20px;
}

.product-card .card-footer {
  justify-content: center;
}
.product-card .card-footer form {
  display: block;
  width: 100%;
}

.admin > div.container {
  margin: 40px 10%;
}

.admin .control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin table {
  width: 100%;
}

.admin table th:first-of-type {
  width: 50%;
}
.admin table td:first-of-type {
  width: 50%;
}

.admin table td {
  vertical-align: middle;
}

.product-card .card-footer button {
  border: none;
  width: 100%;
  height: 50px;
}

.product-card .card-footer button i {
  margin-right: 10px;
}

#cart .cart-item h3 {
  margin: 0;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quantity {
  position: relative;
}

.quantity:before {
  content: "#";
  position: absolute;
  z-index: 10;
  top: 0;
  right: 5px;
  bottom: 0;
  display: flex;
  align-items: center;
  color: gray;
}

.cart-right .field {
  margin-bottom: 0 !important;
}

.price {
  margin: 0 20px;
}

.cart-right {
  display: flex;
  align-items: center;
}

#input-data {
  margin-top: 50px;
}

input[type="file"] {
  display: none;
}

.button {
  margin: 3px;
}

.content ul {
  list-style: none !important;
}

.upload {
  position: relative;
}
.upload > .delete {
  position: absolute;
  top: 4px;
  right: 4px;
}

.upload-image-container {
  width: 100%;
  max-width: 600px; /* Adjust the max-width to control the width of the image */
  aspect-ratio: 3 / 4; /* Ensures the image has a 3:4 aspect ratio */
  overflow: hidden; /* Ensures the image does not overflow the container */
  border-radius: 10px; /* Adjust the value to change the radius of the container */
  margin: 5px auto;
}
.stock-img {
  width: 100%;
  max-width: 110px; /* Adjust the max-width to control the width of the image */
  aspect-ratio: 3 / 4; /* Ensures the image has a 3:4 aspect ratio */
  overflow: hidden; /* Ensures the image does not overflow the container */
  border-radius: 10px; /* Adjust the value to change the radius of the container */
  margin: 5px auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 250px; /* Adjust as needed */
  height: auto;
  cursor: pointer;
}

.upload-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area while maintaining its aspect ratio */
  border-radius: 10px; /* Adjust the value to change the radius of the image */
}

.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center !important;
}
.align-items-start {
  align-items: start !important;
}

.dropdown-content {
  max-height: 150px;
  overflow: auto;
}

.no-item {
  padding: 40px;
  width: 100%;
  text-align: center;
}

.stock-img-container {
  position: relative;
  display: inline-block;
}

.preview {
  visibility: hidden;
  width: 240px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 1;
  top: -140px;
  right: -232px;
  padding: 5px;
  transform: scale(0.98);
  /* transition: opacity 0.1s ease, transform 0.1s ease, visibility 0s 0.1s; */
}

.stock-img-container:hover .preview {
  visibility: visible;
  transform: scale(1);
  transition: opacity 0.2s ease, transform 0.2s ease;
}
